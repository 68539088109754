import { createRouter, createWebHistory } from 'vue-router'
import NovaConsultingView from "@/views/NovaConsultingView.vue";
import ScreenRecorderView from "@/views/ScreenRecorderView.vue";
import CvTemplate1 from "@/views/CvTemplate1.vue";
import CvTemplate2 from "@/views/CvTemplate2.vue";
import CvTemplateSimple from "@/views/CvTemplateSimple.vue";
import Animations from "@/views/Animations.vue";
import LottieAnimationView from "@/views/LottieAnimationView";
import WelcomeStepperView from "@/views/WelcomeStepperView.vue";
import ResumeEditorA from "@/views/ResumeEditorA.vue";
import ResumeEditorB from "@/views/ResumeEditorB.vue";

const routes = [
  {
    path: '/',
    redirect: '/resume-editor-a'
  },
  {
    path: '/resume-editor-a',
    name: 'resume-editor-a',
    component: ResumeEditorA
  },
  {
    path: '/resume-editor-b',
    name: 'resume-editor-b',
    component: ResumeEditorB
  },
  {
    path: '/welcome-stepper',
    name: 'welcome',
    component: WelcomeStepperView
  },
  {
    path: '/lottie',
    name: 'lottie-animation-view',
    component: LottieAnimationView,
  },
  {
    path: '/nova-consulting',
    name: 'nova',
    component: NovaConsultingView
  },
  {
    path: '/screen-recorder',
    name: 'screen',
    component: ScreenRecorderView
  },
  {
    path: '/cv-template-1',
    name: 'template-1',
    component: CvTemplate1
  },
  {
    path: '/cv-template-2',
    name: 'template-2',
    component: CvTemplate2
  },
  {
    path: '/cv-template-simple',
    name: 'template-simple',
    component: CvTemplateSimple
  },
  {
    path: '/animations',
    name: 'animations',
    component: Animations
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
