<template>
  <div ref="animationContainer" class="lottie-animation" />
</template>

<script>
  import lottie from "lottie-web";

  export default {
    name: "LottieAnimation",
    props: {
      animationData: {
        type: Object,
        required: true,
      },
      loop: {
        type: Boolean,
        default: true,
      },
      autoplay: {
        type: Boolean,
        default: true,
      },
    },

    mounted () {
      const animation = lottie.loadAnimation({
        container: this.$refs.animationContainer,
        animationData: this.animationData,
        loop: this.loop,
        autoplay: this.autoplay,
        renderer: "svg",
      });

      const durationInFrames = animation.totalFrames;
      const durationInSeconds = durationInFrames / animation.frameRate;
      this.$emit('animation-loaded', durationInSeconds);
    },
  };
</script>

<style lang="scss">
  .lottie-animation {
    max-width: 650px;
    margin: auto;
  }
</style>
