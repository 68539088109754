<template>
  <main class="welcome-stepper">
    <div class="modal-container flex-center">
      <div class="modal-content">
        <header class="flex flex-between align-center">
          <i>
            <svg width="79" height="26" viewBox="0 0 79 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3899 13.2339C11.1235 14.0438 11.4906 15.037 11.4906 16.2141C11.4906 17.3906 11.1235 18.3865 10.3899 19.2016C9.65596 20.0169 8.72349 20.4245 7.59199 20.4245C6.55228 20.4245 5.69619 20.0943 5.02346 19.4339V24.2172H2.31812V12.2505H4.99363V13.1485H5.02346C5.69619 12.3951 6.55228 12.0182 7.59199 12.0182C8.72349 12.0182 9.65596 12.4235 10.3899 13.2339ZM8.08882 17.561C8.44061 17.2097 8.61652 16.7607 8.61652 16.2141C8.61652 15.6669 8.44807 15.2203 8.11145 14.8745C7.77534 14.5287 7.332 14.3557 6.78141 14.3557C6.25192 14.3557 5.81654 14.5315 5.47503 14.8828C5.13352 15.2336 4.96277 15.6771 4.96277 16.213C4.96277 16.7604 5.13815 17.2097 5.48943 17.561C5.84123 17.9117 6.27172 18.087 6.78141 18.087C7.30114 18.087 7.73702 17.9117 8.08882 17.561Z" fill="#1F2633"/>
              <path d="M12.2547 7.82335C12.5708 7.5033 12.9481 7.34314 13.3863 7.34314C13.8245 7.34314 14.2015 7.5033 14.5178 7.82335C14.8338 8.14288 14.992 8.52439 14.992 8.96814C14.992 9.41189 14.8338 9.79392 14.5178 10.1139C14.2015 10.4342 13.8245 10.5941 13.3863 10.5941C12.9481 10.5941 12.5708 10.4342 12.2547 10.1139C11.9392 9.79392 11.7816 9.41189 11.7816 8.96814C11.7816 8.52439 11.9392 8.14288 12.2547 7.82335ZM11.9955 12.2504V20.1921H14.7009V12.2504H11.9955Z" fill="#1F2633"/>
              <path d="M16.3069 12.2505V9.29321H19.0121V12.2505H20.6631V14.3401H19.0121V16.864C19.0121 17.5969 19.2161 17.963 19.6242 17.963C19.7255 17.963 19.8322 17.9427 19.9441 17.9015C20.0565 17.8599 20.1432 17.8187 20.2043 17.7776L20.297 17.7151L20.9687 19.9286C20.3877 20.2591 19.7253 20.4245 18.9813 20.4245C18.4717 20.4245 18.0334 20.3341 17.6667 20.1536C17.2997 19.9732 17.022 19.7307 16.8335 19.4265C16.6448 19.1219 16.5097 18.8015 16.4282 18.4661C16.3473 18.1307 16.3069 17.7721 16.3069 17.3901V14.3401H15.1753V12.2505H16.3069Z" fill="#1F2633"/>
              <path d="M25.2957 14.263C24.7859 14.263 24.3478 14.4542 23.981 14.836C23.614 15.218 23.4307 15.6771 23.4307 16.213C23.4307 16.7604 23.614 17.225 23.981 17.6068C24.3478 17.9888 24.7859 18.1797 25.2957 18.1797C25.5405 18.1797 25.7721 18.149 25.991 18.087C26.2104 18.0253 26.3708 17.963 26.4724 17.9005L26.6102 17.8078L27.5278 19.712C27.4563 19.7641 27.3571 19.8292 27.2295 19.9068C27.1019 19.9839 26.8113 20.0896 26.3582 20.2235C25.9048 20.3573 25.4183 20.4245 24.8986 20.4245C23.7465 20.4245 22.7346 20.0146 21.863 19.1943C20.992 18.3735 20.5566 17.3901 20.5566 16.2443C20.5566 15.0888 20.992 14.0959 21.863 13.2651C22.7346 12.4339 23.7465 12.0182 24.8986 12.0182C25.4183 12.0182 25.8973 12.0805 26.3356 12.2047C26.7737 12.3284 27.0844 12.4521 27.2675 12.5755L27.5278 12.761L26.6102 14.6349C26.2741 14.387 25.8359 14.263 25.2957 14.263Z" fill="#1F2633"/>
              <path d="M27.8643 20.1922V6.87866H30.5696V13.086H30.6005C31.3033 12.3742 32.0524 12.0182 32.8481 12.0182C33.2349 12.0182 33.619 12.0701 34.0012 12.1735C34.3838 12.2763 34.7511 12.436 35.1028 12.6526C35.4547 12.8693 35.7399 13.1766 35.9587 13.5745C36.1781 13.9719 36.2878 14.4336 36.2878 14.9599V20.1922H33.5815V15.7026C33.5815 15.2901 33.4514 14.9292 33.1916 14.6193C32.9317 14.3097 32.593 14.1547 32.1754 14.1547C31.7673 14.1547 31.3977 14.3149 31.0664 14.6349C30.7352 14.9544 30.5696 15.3104 30.5696 15.7026V20.1922H27.8643Z" fill="#1F2633"/>
              <path d="M37.2041 20.1922V12.2505H39.9095V13.086H39.9404C40.6432 12.3742 41.3923 12.0182 42.1879 12.0182C42.7583 12.0182 43.2932 12.137 43.7926 12.3745C44.2918 12.612 44.669 12.9784 44.9242 13.4735C45.7187 12.5034 46.5854 12.0182 47.5235 12.0182C48.3588 12.0182 49.077 12.2557 49.6785 12.7307C50.2798 13.2052 50.5807 13.9482 50.5807 14.9599V20.1922H47.8743V15.7026C47.8743 15.2797 47.7596 14.9263 47.5307 14.6422C47.3016 14.3584 46.9577 14.2162 46.4989 14.2162C46.1012 14.2162 45.7977 14.3378 45.5887 14.5807C45.3801 14.8232 45.2657 15.1146 45.2451 15.4547V20.1922H42.5398V15.7026C42.5398 15.2797 42.4248 14.9263 42.1951 14.6422C41.966 14.3584 41.6222 14.2162 41.1634 14.2162C40.7458 14.2162 40.4323 14.3479 40.2232 14.611C40.0139 14.8742 39.9095 15.1865 39.9095 15.5474V20.1922H37.2041Z" fill="#35AD74"/>
              <path d="M59.4324 16.6005H53.745C53.745 17.1584 53.9233 17.5711 54.28 17.8391C54.6371 18.1073 55.0246 18.2412 55.4424 18.2412C55.8805 18.2412 56.2266 18.1823 56.4812 18.0641C56.7364 17.9453 57.0269 17.7104 57.3536 17.3589L59.31 18.3495C58.4945 19.7328 57.1391 20.4245 55.2438 20.4245C54.0613 20.4245 53.0471 20.0146 52.2011 19.1943C51.3555 18.3735 50.9327 17.3854 50.9327 16.2297C50.9327 15.0735 51.3555 14.0826 52.2011 13.2568C53.0471 12.4313 54.0613 12.0182 55.2438 12.0182C56.4869 12.0182 57.4986 12.3823 58.2783 13.1099C59.058 13.837 59.4479 14.8766 59.4479 16.2287C59.4479 16.4149 59.4427 16.5388 59.4324 16.6005ZM53.8222 15.0839H56.8032C56.742 14.6709 56.5815 14.3534 56.3218 14.1318C56.0618 13.9097 55.7277 13.7985 55.3199 13.7985C54.8714 13.7985 54.5145 13.9172 54.2491 14.1547C53.9842 14.3922 53.842 14.7021 53.8222 15.0839Z" fill="#35AD74"/>
              <path d="M66.3574 7.42029L71.4482 20.1922H68.3602L67.4272 17.638H62.6276L61.6946 20.1922H58.6066L63.6973 7.42029H66.3574ZM66.449 15.0838L65.0428 11.3682H65.0119L63.6058 15.0838H66.449Z" fill="#1F2633"/>
              <path d="M74.0476 12V20.1922H71.1736V12H74.0476Z" fill="#1F2633"/>
              <path d="M70.6062 8L71.0063 9.09486L72.0875 9.5L71.0063 9.90514L70.6062 11L70.2062 9.90514L69.125 9.5L70.2062 9.09486L70.6062 8Z" fill="#1F2633"/>
              <path d="M71.1 3L71.6334 4.45982L73.075 5L71.6334 5.54018L71.1 7L70.5666 5.54018L69.125 5L70.5666 4.45982L71.1 3Z" fill="#1F2633"/>
              <path d="M74.0625 5L74.8626 7.18973L77.025 8L74.8626 8.81027L74.0625 11L73.2623 8.81027L71.1 8L73.2623 7.18973L74.0625 5Z" fill="#1F2633"/>
            </svg>
          </i>
          <span class="inter"
                :class="{'last-step': stepNumber === content.length - 1}">
            {{ stepNumber + 1 }}/{{ content.length }}
          </span>
        </header>

        <div class="content-body text-center">
          <h1>
            {{ title }}
          </h1>

          <div>
            <p v-if="body.main"
               class="body-copy"
               v-html="body.main" />
            
            <div class="hello-slide"
                 v-show="stepNumber === 0">
              <div class="icon-text flex flex-center">
                <div class="left-side">
                  <span class="itim block">
                    YOUR STORY
                  </span>
                  <img src="../assets/welcome/your-story-doodle.png" alt="" />
                </div>
                <div class="right-side">
                  <span class="itim block">
                    THEIR STORY
                  </span>
                  <img src="../assets/welcome/their-story-doodle.png" alt="" />
                </div>
              </div>
              <footer class="flex flex-center">
                <i v-html="icons.redTriangle" />
                <p class="footer-note"
                   v-html="body.footer" />
              </footer>
            </div>

            <div class="what-do-we-do"
                 v-show="stepNumber === 1">

              <div class="flex flex-between">
                <div v-for="({icon, caption, key}, index) in body.list"
                     :key="index"
                     class="flex">
                  <div class="flex columns">
                    <i v-html="icon"
                       :class="`${key} block`" />
                    <span class="m-t-auto itim"
                          v-html="caption" />
                  </div>
                  <img v-if="index + 1 < body.list.length"
                       :class="`right-arrow arrow-${key}`"
                       src="../assets/welcome/right-arrow.png" alt="right-arrow" />
                </div>
              </div>

              <footer class="flex flex-center">
<!--                <i v-html="icons.alert" />-->
                <p v-html="body.footer"
                   class="footer-note text-center" />
              </footer>
            </div>

            <div class="why-do-we-do-this"
                 v-show="stepNumber === 2">
              <div class="relative flex flex-center">
                <span class="job-description itim">
                  JOB DESCRIPTION
                </span>
                <img src="../assets/welcome/different-icon.png" alt="" class="absolute" />
                <i v-html="icons.resumeComparison" class="block" />
                <span class="your-resume itim">
                  YOUR RESUME
                </span>
              </div>

              <p class="footer-note"
                 v-html="body.footer" />
            </div>

            <div class="core-functionalities text-left"
                 v-show="stepNumber === 3">
              <ul class="functionalities">
                <li v-for="(item, index) in body.features"
                    :key="index">
                  <img src="../assets/welcome/check.jpg" alt="check" />
                  <span v-html="item" />
                </li>
              </ul>

              <h3 class="text-center">
                Benefits
              </h3>

              <ul class="benefits flex flex-center">
                <li v-for="(item, index) in body.benefits"
                    :key="index"
                    class="flex align-center">
                  <span>{{ item }}</span>
                  <i v-if="index < body.benefits.length - 1">
                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4.5" cy="4.66669" r="4.5" fill="#4D795E"/>
                    </svg>
                  </i>
                </li>
              </ul>

              <footer class="flex align-center flex-center">
                <i v-html="icons.info" />
                <span>
                  {{ body.footer }}
                </span>
              </footer>
            </div>

          </div>
        </div>

        <footer class="flex flex-between align-center">
          <button :class="{'first-step': stepNumber === 0}"
                  class="back-btn"
                  :disabled="stepNumber === 0"
                  @click="handlePrevious">
            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 2L2 6.5L7 11" stroke="#7F7F7F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>

          <button class="next-btn inter"
                  :disabled="disableBtn"
                  @click="handleNext">
            {{ stepNumber < content.length - 1 ? 'Next' : 'Let’s get started' }}
          </button>

          <button class="skip-btn inter"
                  :class="{'last-step': stepNumber === content.length - 1}">
            skip
          </button>
        </footer>
      </div>
    </div>
  </main>
</template>

<script setup>
  import {computed, nextTick, reactive, ref} from "vue";
  import {icons} from "@/store/data";
  import gsap from "gsap";

  const stepNumber = ref(0);
  const disableBtn = ref(false);
  const body = computed(() => content[stepNumber.value].body);
  const title = computed(() => content[stepNumber.value].title);
  const userName = 'Jane';
  const content = reactive([
    {
      title: `Hi ${userName},`,
      body: {
        main: `I’m <b>PitchMeAI</b>, and I’m here to show you how to make </br>
        your resume speak directly to each job opportunity using </br>
        the power of smart matching.`,
        footer: `Your skills aren’t the issue — it’s how you’re showcasing them.`
      }
    },
    {
      title: 'What do we do?',
      body: {
        main: `We <b>analyze, edit</b> and <b>refine</b> the content and layout of </br>
              your resume to match the recruiter's requirements.`,
        list: [
          {icon: icons.uploadResume, caption: `UPLOAD <br /> RESUME`, key: 'upload'},
          {icon: icons.analyzeContent, caption: `ANALYZE <br /> CONTENT`, key: 'analyze-content'},
          {icon: icons.analyzeJD, caption: `ANALYZE <br /> JOB DESCRIPTION`, key: 'analyze-jd'},
          {icon: icons.PaiProcess, caption: `PITCHMEAI <br /> PROCESS`, key: 'process'},
          {icon: icons.generatedResume, caption: `GENERATED <br /> RESUME`, key: 'generate'},
        ],
        footer: `We remove irrelevant content and add what matters, keeping your resume </br> focused and impactful - <b>less is more.</b>`
      }
    },
    {
      title: 'Why do we do this?',
      body: {
        main: `Your current resume <b>content</b> is <b>too different</b> from the <b>job description</b>.
        </br>
        Your resume's layout and format <b>don’t meet</b> the recruiter's expectations.`,
        footer: `You must make it effortless for the recruiter to see why they should talk to you.`
      }
    },
    {
      title: 'PitchMeAI core functionalities',
      body: {
        features: [
          `Create job-specific <b>resumes</b>`,
          `Generate personalized <b>intro emails</b> to engage with the recruiter 1-1`,
          `Find recruiters <b>emails</b> with 90% accuracy`,
          `<b>Customize</b> resumes and emails fully`,
          `<b>Track</b> all generated resume`,
        ],
        benefits: [
          'Save time by not using chatGPT',
          'Boost recruiters response rates',
          'Improve match for each application'
        ],
        footer: `Start with 3 free credits to try it out, then enjoy a free trial before upgrading.`
      }
    }
  ]);

  function handleNext () {
    if (stepNumber.value >= content.length - 1) return;

    const currentContent = document.querySelector('.content-body > div');
    const currentTitle = document.querySelector('h1');
    disableBtn.value = true;

    gsap.to([currentTitle, currentContent], {
      opacity: 0,
      x: -300,
      duration: 0.6,
      ease: "power3.inOut",
      onComplete: () => {
        stepNumber.value++;
        disableBtn.value = false;

        nextTick(() => {
          const newContent = document.querySelector('.content-body > div');
          const newTitle = document.querySelector('h1');

          gsap.set([newTitle, newContent], {
            opacity: 0,
            x: 300
          });

          gsap.to([newTitle, newContent], {
            opacity: 1,
            x: 0,
            duration: 0.3,
            ease: "power3.out"
          });
        });
      }
    });
  }

  function handlePrevious () {
    if (stepNumber.value <= 0) return;

    const currentContent = document.querySelector('.content-body > div');
    const currentTitle = document.querySelector('h1');

    gsap.to([currentTitle, currentContent], {
      opacity: 0,
      x: 200,  // Reverse direction
      duration: 0.4,
      ease: "back.in(1.7)",
      onComplete: () => {
        stepNumber.value--;

        nextTick(() => {
          const newContent = document.querySelector('.content-body > div');
          const newTitle = document.querySelector('h1');

          gsap.set([newTitle, newContent], {
            opacity: 0,
            x: -200  // Start from left
          });

          gsap.to([newTitle, newContent], {
            opacity: 1,
            x: 0,
            duration: 0.4,
            ease: "elastic.out(1, 0.7)"
          });
        });
      }
    });
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Itim&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

  .welcome-stepper {
    @media only screen and (min-width: 0) {
      height: 100%;
      background-image: url('../assets/welcome/linkedin-background.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      h1, h1 b, p, p b, p i, span {
        font-family: "Lora", serif;
        font-style: normal;
        color: #272727;
      }

      .modal-container {
        height: 100%;
        width: calc(100% - 205px);
        .modal-content {
          width: 100%;
          height: 100%;
          max-width: 814px;
          max-height: 550px;
          padding: 16px 24px 22px 16px;
          overflow-x: hidden;
          background-color: white;
          border-radius: 8px;
          display: flex;
          flex-direction: column;

          header {
            span {
              font-size: 1.4rem;
              opacity: 0.6;
            }
          }

          .content-body {
            h1 {
              margin-top: 0;
              margin-bottom: 8px;
              color: #4D795E;
              font-size: 2.9rem;
              font-weight: 600;
            }

            p {
              margin: 0;
              font-size: 1.9rem;
              line-height: 2.9rem;
              b {
                font-size: 2.1rem;
              }
            }

            .body-copy {
              font-size: 2.1rem;
            }

            .hello-slide {
              .icon-text {
                margin-top: 47px;
                span {
                  font-size: 1.6rem;
                  position: relative;
                  z-index: 1;
                }
                img {
                  height: 125px;
                  margin-top: 8px;
                }
                .left-side {
                  margin-right: 53px;
                }
                .right-side {
                  span {
                    transform: translateX(7px);
                  }
                }
              }
              footer {
                margin-top: 67px;
                i {
                  margin-right: 10px;
                  transform: translateY(1px);
                }
              }
            }

            .what-do-we-do {
              > div {
                width: 662px;
                margin: 55px auto 0;
                transform: translateX(-8px);
                .upload {
                  svg {
                    height: 100px;
                    transform: translate(0, -12px);
                  }
                  + span {
                    transform: translateX(5px);
                  }
                }
                .analyze-content svg {
                  height: 93px;
                  transform: translateY(7px);
                }
                .analyze-jd {
                  svg {
                    height: 70px;
                    transform: translateY(7px);
                  }
                  + span {
                    transform: translateX(-10px);
                  }
                }
                .process {
                  svg {
                    height: 76px;
                    transform: translateY(2px);
                  }
                  + span {
                    transform: translateX(-4px);
                  }
                }
                .generate {
                  svg {
                    height: 76px;
                    transform: translate(8px, 2px);
                  }
                  + span {
                    transform: translateX(5px);
                  }
                }
                span {
                  font-size: 1.4rem;
                }
                .right-arrow {
                  width: 40px;
                  align-self: flex-start;
                  transform: translate(-2px, 28px);
                  &.arrow-analyze-jd {
                    transform: translate(-6px, 28px);
                  }
                  &.arrow-process {
                    transform: translate(6px, 28px);
                  }
                }
              }
              footer {
                padding-top: 80px;
                align-items: flex-start;
                i {
                  margin-right: 6px;
                  transform: translateY(1px);
                }
              }
            }

            .why-do-we-do-this {
              i svg {
                height: 160px;
                margin-top: 73px;
              }
              img {
                height: 58px;
                left: 368px;
                top: 101px;
              }
              span {
                font-size: 1.5rem;
                &.job-description {
                  transform: translate(45px, -65px);
                }
                &.your-resume {
                  transform: translateY(-4px);
                }
              }
              .footer-note {
                margin-top: 50px;
              }
            }

            .core-functionalities {
              margin: 45px auto 0;
              ul,
              li {
                list-style-type: none;
                font-size: 1.8rem;
                font-family: Lora, sans-serif!important;
                b {
                  font-weight: 600;
                  font-family: Lora, sans-serif!important;
                }
              }
              .functionalities {
                width: 600px;
                margin-top: 22px;
                margin-left: 105px;
                li {
                  margin-bottom: 12px;
                  img {
                    margin-right: 7px;
                    transform: translateY(2px);
                  }
                }
              }
              h3 {
                margin-top: 55px;
                margin-bottom: 10px;
                font-size: 2rem;
                font-weight: 600;
                color: #4D795E;
                font-family: Lora, sans-serif!important;
              }
              .benefits {
                font-family: Lora, sans-serif!important;
                li {
                  font-size: 1.4rem;
                  i {
                    margin: 0 12px;
                  }
                }
              }
              footer {
                margin-top: 57px;
                i {
                  margin-right: 4px;
                  svg {
                    height: 28px;
                  }
                }
                span {
                  font-size: 1.4rem;
                  font-weight: 500;
                }
              }
            }

            .footer-note {
              margin: 0;
              font-size: 1.8rem;
              //line-height: 20px;
            }
          }

          > footer {
            margin-top: auto;
            button {
              &:disabled {
                opacity: 0.6;
                cursor: default;
              }
            }
            .back-btn,
            .skip-btn {
              background-color: transparent;
              transition: transform 0.1s ease-in-out;
            }
            .back-btn {
              width: 31px;
              transform: translateY(3px);
              &.first-step {
                opacity: 0!important;
              }
              &[disabled] {
                opacity: 0.5;
                cursor: default;
              }
              &:hover {
                transform: scale(1.2) translateY(3px);
              }
            }
            .skip-btn {
              color: #4D795E;
              font-size: 1.6rem;
              font-weight: 700;
              opacity: 0.6;
              transform: translateY(3px);
              &:hover {
                transform: scale(1.2) translateY(3px);
              }
            }
            .next-btn {
              color: white;
              background-color: #35AD74;
              border-radius: 4px;
              padding: 8px 28px;
              font-weight: 500;
            }
          }

          b {
            font-weight: 700;
          }

          .itim {
            font-family: "Itim", cursive;
            font-style: normal;
          }
          .inter {
            font-family: "Inter", cursive;
            font-style: normal;
          }
          .last-step {
            visibility: hidden;
            pointer-events: none;
          }
        }
      }
    }
  }
</style>