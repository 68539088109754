<template>
  <div class="animations-view">

    <div>
      <LottieAnimation :animationData="animationData"
                       :loop="true"
                       :autoplay="true" />
      <div class="text-container">
        <p class="scrolling-text" ref="textElement">{{ currentPhrase }}</p>
      </div>
    </div>

    <div class="flex flex-center m-t-24">
      <button v-for="(item, index) in easingTypes"
              :key="index"
              class="m-r-24"
              @click="handleEasingType(item)">
        {{ item }}
      </button>
    </div>
    <p class="text-center">
      {{ currentEasing }}
    </p>
  </div>
</template>

<script setup>
  import { gsap } from 'gsap';
  import LottieAnimation from "@/components/LottieAnimation.vue";
  import animationData from "@/assets/animations/scanning-animation-two.json";
  import {onMounted, reactive, ref} from "vue";

  const easingTypes = reactive(['power1', 'power2', 'power3', 'power4', 'circ', 'expose', 'slow']);
  const phrases = reactive([
        "Scanning documents...",
        "Extracting key information...",
        "Processing resume data...",
        "Analyzing work experience...",
        "Identifying skills...",
        "Reading educational background...",
        "Converting text to data...",
        "OCR processing..."
      ])
  const currentPhrase = ref('')
  const currentIndex = ref(0)
  const textElement = ref(null);
  const currentEasing = ref('power1');
  const easings = {
    power1: {
      in: "power1.in",
      out: "power1.out"
    },
    power2: {
      in: "power2.in",
      out: "power2.out"
    },
    power3: {
      in: "power3.in",
      out: "power3.out"
    },
    power4: {
      in: "power4.in",
      out: "power4.out"
    },
    circ: {
      in: "circ.in",
      out: "circ.out"
    },
    expose: {
      in: "expo.in",
      out: "expo.out"
    },
    slow: {
      in: "back.in(1.7)",
      out: "back.out(1.7)"
    },
  }

  onMounted(() => {
    initTextAnimation();
  })

  function initTextAnimation () {
    const animatePhrase = () => {
      currentPhrase.value = phrases[currentIndex.value];

      gsap.set(textElement.value, {
        x: '100%',
        opacity: 0
      });

      gsap.timeline()
          .to(textElement.value, {
            x: '0%',
            opacity: 1,
            duration: 2,
            ease: easings[currentEasing.value].in,
          })
          .to(textElement.value, {
            duration: 2.5,
          })
          .to(textElement.value, {
            x: '-100%',
            opacity: 0,
            duration: 2,
            ease: easings[currentEasing.value].out,
            onComplete: () => {
              currentIndex.value = (currentIndex.value + 1) % phrases.length;
              animatePhrase();
            },
          });
    };

    animatePhrase();
  }

  function handleEasingType (type) {
    currentEasing.value = type;
  }
</script>

<style lang="scss">
  .animations-view {
    @media only screen and (min-width: 0) {

      .animation-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 600px;
      }

      .text-container {
        position: relative;
        width: 100%;
        height: 2.5em;
        display: flex;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .scrolling-text {
        padding: 0 1rem;
        font-size: 1.7rem;
        font-weight: 550;
        color: #3b4672;
        text-align: center;
        margin: 0;
        position: absolute;
        font-family: 'Karla', sans-serif;
        letter-spacing: 0.5px;
      }

      .text-center{
        font-size: 1.3rem;
        font-weight: 500;
      }

      button {
        background-color: #f1d6d9;
        border-radius: 4px;
      }
    }
  }
</style>
